<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { downloadAction, getAction, getParams } from '@/command/netTool'
import Big from 'big.js'

export default {
  name: 'business',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getMerchantReportPage',
      params: { current: 1, pageSize: 10 },
      paramsValue: {},
      businessIdList: [],
      headerTypeData: {
        fromType: [
          {
            name: '酒店民宿',
            value: '13',
          },
          {
            name: '景点门票',
            value: '1201',
          },
          {
            name: '渔家乐',
            value: '1202',
          },
          {
            name: '农家特产',
            value: '1',
          },
          {
            name: '农家乐',
            value: '2',
          },
        ],
        businessId: [],
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getTotal(params) {
      return new Promise((resolve) => {
        // if (this.totalData) {
        //   resolve(this.totalData)
        // } else {
        this.params = {
          ...this.params,
          ...params,
        }
        getAction('/reportForm/getMerchantReportOrderTotal', params).then((e) => {
          this.totalData = e.data
          resolve(e.data)
        })
        // }
      })
    },
    getSum(list) {
      let orderCount = 0
      let totalAmount = 0
      let productQuantity = 0
      let productQuantityCompleted = 0
      let totalAmountCompleted = 0
      let refundCount = 0
      let refundAmount = 0
      let salesJsAmount = 0
      let feeAmount = 0
      list.forEach((e) => {
        orderCount = parseFloat(new Big(e.orderCount || 0).plus(orderCount))
        totalAmount = parseFloat(new Big(e.totalAmount || 0).plus(totalAmount))
        productQuantity = parseFloat(new Big(e.productQuantity || 0).plus(productQuantity))
        productQuantityCompleted = parseFloat(new Big(e.productQuantityCompleted || 0).plus(productQuantityCompleted))
        totalAmountCompleted = parseFloat(new Big(e.totalAmountCompleted || 0).plus(totalAmountCompleted))
        refundCount = parseFloat(new Big(e.refundCount || 0).plus(refundCount))
        refundAmount = parseFloat(new Big(e.refundAmount || 0).plus(refundAmount))
        salesJsAmount = parseFloat(new Big(e.salesJsAmount || 0).plus(salesJsAmount))
        feeAmount = parseFloat(new Big(e.feeAmount || 0).plus(feeAmount))
      })
      return {
        businessName: '合计',
        orderCount,
        totalAmount,
        productQuantity,
        productQuantityCompleted,
        totalAmountCompleted,
        refundCount,
        refundAmount,
        salesJsAmount,
        feeAmount,
        type: '1',
      }
    },
    getList(params = this.params) {
      this.getTotal(params).then((total) => {
        getAction('/reportForm/getMerchantReportPage', params).then((e) => {
          this.records = e.data['records'].map((e) => ({ ...e, dateTypeQuery: params.dateTypeQuery }))
          this.params = params
          this.params['current'] = e.data['current']
          this.params['pageSize'] = e.data['pageSize']
          this.current = e.data['current']
          this.total = e.data['total']
          if (this.records.length !== 0) {
            this.records.push(this.getSum(e.data['records']))
            // this.records.push({
            //   orderCount: total.orderCountHj,
            //   totalAmount: total.totalAmountHj,
            //   productQuantityCompleted: total.productQuantityCompletedHj,
            //   refundAmount: total.refundAmountHj,
            //   businessName: '合计',
            //   refundCount: total.refundCountHj,
            //   salesJsAmount: total.salesJsAmountHj,
            //   totalAmountCompleted: total.totalAmountCompletedHj,
            //   type: '1',
            // })
            this.records.push({
              ...total,
              orderCount: total.orderCount,
              totalAmount: total.totalAmount,
              feeAmount: total.feeAmount,
              businessName: '总计',
              type: '1',
            })
          }
        })
      })
    },
    getHeader() {
      return [
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
        },
        {
          type: 'select',
          name: '产品类型',
          key: 'fromType',
          onChange: (e) => {
            if (e == 1 || e == 2) {
              getAction('/api/commodity/farmMainBody/list').then((res) => {
                const data = res.data.map((d) => ({ name: d.mainBodyName, value: d.id }))
                console.log(data)
                this.headerTypeData = {
                  fromType: this.headerTypeData.fromType,
                  businessId: data,
                }
              })
            } else if (e == 1201) {
              getAction('/api/farmTicketScenic/list?upDown=0&scenicType=0').then((res) => {
                const data = res.data.map((d) => ({ name: d.name, value: d.id }))
                this.headerTypeData = {
                  fromType: this.headerTypeData.fromType,
                  businessId: data,
                }
              })
            } else if (e == 1202) {
              getAction('/api/farmTicketScenic/list?upDown=0&scenicType=2').then((res) => {
                const data = res.data.map((d) => ({ name: d.name, value: d.id }))
                this.headerTypeData = {
                  fromType: this.headerTypeData.fromType,
                  businessId: data,
                }
              })
            } else if (e == 13) {
              getAction('/api/hotel/list?upDown=0').then((res) => {
                const data = res.data.map((d) => ({ name: d.name, value: d.id }))
                this.headerTypeData = {
                  fromType: this.headerTypeData.fromType,
                  businessId: data,
                }
              })
            }
          },
          // typeData: [
          //   {
          //     name: '酒店民宿',
          //     value: '13',
          //   },
          //   {
          //     name: '景点门票',
          //     value: '12',
          //   },
          // ],
        },
        {
          name: '商户名称',
          type: 'select',
          key: 'businessId',
          props: {
            mode: 'multiple',
            placeholder: '请选择产品类型后进行商户查询',
          },
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '商户名称',
          dataIndex: 'businessName',
        },

        {
          title: '总订单量',
          dataIndex: 'orderCount',
          sorter: (a, b, sortOrder) => {
            if (a.businessName == '合计' || a.businessName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.orderCount - b.orderCount
            }
          },
        },
        {
          title: '总订单金额',
          dataIndex: 'totalAmount',
          sorter: (a, b, sortOrder) => {
            if (a.businessName == '合计' || a.businessName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmount - b.totalAmount
            }
          },
        },
        {
          title: '退款数量',
          dataIndex: 'refundCount',
          sorter: (a, b, sortOrder) => {
            if (a.businessName == '合计' || a.businessName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundCount - b.refundCount
            }
          },
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
          sorter: (a, b, sortOrder) => {
            if (a.businessName == '合计' || a.businessName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundAmount - b.refundAmount
            }
          },
        },
        {
          title: '核销数量',
          dataIndex: 'productQuantityCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.businessName == '合计' || a.businessName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.productQuantityCompleted - b.productQuantityCompleted
            }
          },
        },
        {
          title: '核销金额',
          dataIndex: 'totalAmountCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.businessName == '合计' || a.businessName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmountCompleted - b.totalAmountCompleted
            }
          },
          customRender: (text) => {
            return <span>{parseFloat(text).toFixed(2)}</span>
          },
        },
        {
          title: '手续费',
          dataIndex: 'feeAmount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.feeAmount - b.feeAmount
            }
          },
        },
        {
          title: '结算金额',
          dataIndex: 'salesJsAmount',
          sorter: (a, b, sortOrder) => {
            if (a.businessName == '合计' || a.businessName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.salesJsAmount - b.salesJsAmount
            }
          },
        },
        {
          dataIndex: 'a1',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            if (records.businessName == '合计' || records.businessName == '总计') {
              return []
            }
            return [
              {
                name: '详情',
                onClick: () => {
                  const { dateGe, dateLe } = this.params
                  this.$router.push(
                    `/dataReport/businessDetail?businessId=${records.businessId}&fromType=${
                      records.fromType || ''
                    }&businessName=${records.businessName || ''}&dateGe=${dateGe || ''}&dateLe=${dateLe || ''}`
                  )
                },
              },
            ]
          },
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const { dateGe, dateLe, fromType, businessId } = this.params
            let urlParam = `fromType=${fromType || ''}`
            if (businessId) {
              urlParam = urlParam + `&businessId=${businessId}`
            }
            if (dateGe) {
              urlParam = urlParam + `&dateGe=${dateGe}&dateLe=${dateLe}`
            }
            if (this.businessIdList.length) {
              urlParam = urlParam + `&businessIdList=${this.businessIdList}`
            }
            downloadAction(`/api/reportForm/exportMerchantReport?${urlParam}`, '商户报表.xlsx')
          },
        },
      ]
    },
    selectRow(data, records) {
      this.businessIdList = records.map((d) => d.businessId)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
        onChange={this.onChange}
        headerTypeData={this.headerTypeData}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
